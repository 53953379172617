import React from "react"
import {Container, Row, Col} from "reactstrap"
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import BulletList from "./BulletList"
import Divider from "../Common/Divider"
import ServicesSubSection from "./ServicesSubSection"
import ServicesTypeCarousel from "./ServicesTypesCarousel"
import greyBackground from "../../images/services/grey-background.png"
import creationFlowchart from "../../images/services/creation-flowchart.png"

const ParentDiv = styled.div`
    margin-bottom: 40px;
`

const DiagramContainer = styled(Container)`
	padding: 0px;
`

const DiagramStyleDiv = styled.div`
	background-image: url(${greyBackground});
	margin-top: 60px;
	padding-top: 50px;
	padding-bottom: 50px;
	padding-left: 15px;
	padding-right: 10px;

	${breakpoint('sm')`
		padding-left: 20px;
	`}

	${breakpoint('md')`
		padding-left: 80px;
	`}

	${breakpoint('lg')`
		padding-left: 110px;
	`}

	${breakpoint('xl')`
		padding-left: 230px;
	`}
`

const ServicesTitle = styled.h3`
	font-size: 20px;
	font-weight: 500;
	font-family: 'Libre Franklin';
	color: #212b36;
	margin-top: 50px;
	width: 100%;

	${breakpoint('sm')`
		font-size: 36px;
	`}
`

const ServicesSubtitle = styled.p`
	font-size: 14px;
	font-family: 'Libre Franklin';
	color: #454f5b;

	${breakpoint('sm')`
		font-size: 18px;
	`}
`

const ServicesSectionTitle = styled.h3`
	font-size: 20px;
	font-family: 'Libre Franklin';
	color: #212b36;
	margin-top: 50px;
	margin-bottom: 0px;

	${breakpoint('sm')`
		font-size: 36px;
		font-weight: 500;
	`}
`

const ServicesSectionSubtitle = styled.p`
	font-size: 20px;
	font-family: 'Libre Franklin';
	color: #919eab;
	margin-bottom: 20px;

	${breakpoint('sm')`
		font-size: 36px;
	`}
`

const ServicesColumnTextDiv = styled.div`
	padding-left: 0px;
`

const ServicesColumnText = styled.p`
	font-size: 14px;
	color: #454f5b;
	font-family: 'Libre Franklin';

	${breakpoint('sm')`
		font-size: 16px;
	`}
`

const DesktopContent = styled.div`
	display: none;

	${breakpoint('sm')`
		display: inline;
	`}
`

const MobileCarouselDiv = styled.div`
	padding-top: 50px;

	${breakpoint('sm')`
		display: none;
	`}
`

function constructDesktopServices(allServices){
	let desktopServiceSections = [];
	for (const index in allServices){
    const thisService = allServices[index];
		desktopServiceSections.push(
      <div key={index}>
      <a id={thisService.anchor}> </a>
			<ServicesSubSection
				key={index}
        id={thisService.anchor}
				serviceName={thisService.title}
        serviceText={thisService.bodyText.bodyText}
        serviceBullets={thisService.technologyBulletList}
			/>
      </div>
		)
	}
	return desktopServiceSections;
}



const Services = (props) => {
  const allServices = props.services.allContentfulService.nodes;
  return (
	<ParentDiv>
		<Container>
			<Row>
				<Col sm="12">
					<ServicesTitle>We create digital products</ServicesTitle>
				</Col>
			</Row>
			<Row>
				<Col md="6" sm="12">
					<ServicesSubtitle>
						This cultivates into many things like mobile apps, IOT interfaces,
						enterprise solutions and data management software.
					</ServicesSubtitle>
				</Col>
			</Row>
		</Container>
		<DiagramContainer fluid>
			<DiagramStyleDiv>
				<img src={creationFlowchart} alt=""/>
			</DiagramStyleDiv>
		</DiagramContainer>
		<Container>
			<Row xs="12">
				<Col>
					<ServicesSectionTitle>
						Custom Development
					</ServicesSectionTitle>
				</Col>
			</Row>
			<Row xs="12">
				<Col>
					<ServicesSectionSubtitle>
						Is at the heart of what we do
					</ServicesSectionSubtitle>
				</Col>
			</Row>
			<Row>
				<Col>
					<ServicesColumnTextDiv md="6" sm="12">

            <ServicesColumnText> Developing custom software that creates a better experience for your customers is our mandate. We break down what needs to be done and prioritize to make this process more manageable. We start by sitting down with you and strategizing this process and aligning on what real problems need solutions. </ServicesColumnText>
            <ServicesColumnText> We are focused on being agile which means involving you at important steps and getting what we build in front of you and your customers quick and as often as needed to render useful feedback. We value extreme transparency and integrity; for us, this means we are always in front of the problems for success to quickly follow. If you are successful, we are successful. </ServicesColumnText>
            <ServicesColumnText> Our focus is to make custom software development affordable and accessible to small and medium-sized businesses. </ServicesColumnText>
					</ServicesColumnTextDiv>
					<ServicesColumnTextDiv md="6" sm="12">
						<BulletList
							title="A list of your technical processes"
							listItems={['Agile Development', 'Scrum', 'Kanban', 'Environments and Testing']}/>
					</ServicesColumnTextDiv>
				</Col>
			</Row>
		</Container>
		<DiagramContainer fluid>
			<Divider />
		</DiagramContainer>
		<Container>
			<DesktopContent>
        {constructDesktopServices(allServices)}
			</DesktopContent>
		</Container>
		<MobileCarouselDiv>
			<ServicesTypeCarousel allServices={allServices}/>
		</MobileCarouselDiv>
	</ParentDiv>
)}

export default Services;
